import React from 'react';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import SEO from '@/components/SEO';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;

  p {
    margin: 50px 0;
  }

  ol {
    margin: 0 0 15px 30px;
    list-style: decimal;
  }

  ol,
  li {
    margin-bottom: 10;
  }
`;

const TermsOfService = () => (
  <Layout>
    <SEO title="Terms of Service" />

    <Container>
      {/* <div className="text-center text-5xl mt-32 mb-20">Terms of Service</div> */}
      <div className="text-center text-5xl mt-0 mb-20">Terms of Service</div>
      <div>
        <p>
          <strong>Last Updated: June 10, 2023</strong>
        </p>
        <p>
          THE VOLATILITY TOKENS&nbsp; V1 / V2 / AMM AND VOLMEX PERPETUAL
          CONTRACTS ARE NOT OFFERED TO PERSONS OR ENTITIES WHO RESIDE IN, ARE
          CITIZENS OF, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A REGISTERED
          OFFICE IN THE UNITED STATES OF AMERICA (COLLECTIVELY, &ldquo;
          <strong>U.S. PERSONS</strong>&rdquo;). MOREOVER, NONE OF OUR OTHER
          SERVICES ARE OFFERED TO PERSONS OR ENTITIES WHO RESIDE IN, ARE
          CITIZENS OF, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A REGISTERED
          OFFICE IN ANY RESTRICTED TERRITORY, AS DEFINED BELOW (ANY SUCH PERSON
          OR ENTITY FROM A RESTRICTED TERRITORY, A &ldquo;
          <strong>RESTRICTED PERSON</strong>&rdquo;). WE DO NOT MAKE EXCEPTIONS;
          THEREFORE, IF YOU ARE A U.S. PERSON, YOU MUST NOT ATTEMPT TO USE OUR
          VOLATILITY TOKENS V1 / V2 / AMM OR VOLMEX PERPETUAL CONTRACTS AND IF
          YOU ARE A RESTRICTED PERSON, YOU MUST NOT ATTEMPT TO USE ANY OF THE
          SERVICES, AS DEFINED BELOW. USE OF A VIRTUAL PRIVATE NETWORK (&ldquo;
          <strong>VPN</strong>&rdquo;) TO CIRCUMVENT THE RESTRICTIONS SET FORTH
          HEREIN IS PROHIBITED.
        </p>
        <p>
          These terms of use (&ldquo;<strong>Terms</strong>&rdquo;), are entered
          into between Volmex Labs Corporation (&ldquo;<strong>Volmex</strong>
          ,&rdquo; &ldquo;<strong>volmex.finance</strong>,&rdquo; &ldquo;
          <strong>we</strong>,&rdquo; &ldquo;<strong>us,</strong>&rdquo; and
          &ldquo;<strong>our</strong>&rdquo;) and you concerning your use of,
          and access to, Volmex&rsquo;s websites including
          https://volmex.finance (the &ldquo;<strong>Site</strong>&rdquo;), web
          applications, mobile applications, smart contracts, decentralized
          applications, APIs, all other software that Volmex has developed for
          issuing, redeeming, and trading tokens tracking the implied and
          realized volatility of various assets, including the issuing or
          acquiring of volatility tokens V1 / V2 / Automated Market Maker
          (&ldquo;<strong>AMM</strong>&rdquo;) (&ldquo;
          <strong>Volatility Tokens</strong>&rdquo;) and Volmex Perpetual
          Contracts , and the computation and storage scalability services
          including Polygon and Arbitrum (collectively, the &ldquo;
          <strong>Services</strong>&rdquo;).&nbsp;
        </p>
        <p>
          These Terms expressly cover your rights and obligations, and our
          disclaimers and limitations of legal liability, relating to your use
          of, and access to, the Site and the Services. By clicking &ldquo;I
          agree&rdquo; (or a similar language) to these Terms, acknowledging
          these Terms by other means, or otherwise accessing or using the Site
          or the Services, you accept and agree to be bound by and to comply
          with these Terms, including, without limitation, the mandatory
          arbitration provision in Section 13. You also agree that Volmex does
          not provide execution, settlement, or clearing services of any kind
          and is not responsible for the transactions automated through the
          Services. If you do not agree to these Terms, then you must not access
          or use the Site or the Services.
        </p>
        <p>
          Your submission of information, including any personally identifiable
          information (&ldquo;<strong>PII</strong>&rdquo;), through or in
          connection with the Site is governed by the terms of our privacy
          policy as updated from time to time, available at
          https://volmex.finance/privacy (&ldquo;<strong>Privacy Policy</strong>
          &rdquo;). All such additional terms, guidelines, and rules, including
          our Privacy Policy, are incorporated by reference into these Terms.
        </p>
        <p>
          Please carefully review the disclosures and disclaimers set forth in
          Section 11 in their entirety before using any software initially
          developed by Volmex. The information in Section 11 provides important
          details about the legal obligations associated with your use of the
          Services.&nbsp;
        </p>
        <p>
          <strong>1. Modifications To These Terms</strong>
        </p>
        <p>
          1.1 We reserve the right, in our sole discretion, to modify these
          Terms from time to time. If we make changes, we will provide you with
          notice of such changes, such as by providing notice through the
          Services or updating the &ldquo;Last Updated&rdquo; date at the top of
          these Terms. Unless we state otherwise in our notice, all such
          modifications are effective immediately, and your continued use of the
          Site and the Services after we provide that notice will confirm your
          acceptance of the changes. If you do not agree to the amended Terms,
          then you must stop using the Site and the Services.
        </p>
        <p>
          <strong>2. Use of Services</strong>
        </p>
        <p>
          2.1 As a condition to accessing or using the Services or the Site, you
          represent and warrant to Volmex the following:
        </p>
        <ul>
          <li>
            if you are entering into these Terms as an individual, then you are
            at least 18 years old or of legal age in the jurisdiction in which
            you reside and you have the legal capacity to enter into these Terms
            and be bound by them;
          </li>
          <li>
            if you are entering into these Terms as an entity, then you must
            have the legal authority to accept these Terms on that
            entity&rsquo;s behalf, in which case &ldquo;you&rdquo; (except as
            used in this paragraph) will mean that entity;
          </li>
          <li>
            you are the exclusive beneficial and/or legal owner of any digital
            asset used or to be used for the purchase of any digital asset on
            the Site; if you are entering into these Terms to access Volatility
            Tokens or Volmex Perpetual Contracts or will in the future access
            Volatility Tokens or Volmex Perpetual Contracts, then you are not a
            U.S. Person;
          </li>
          <li>
            you are not a resident, national, or agent of Antigua and Barbuda,
            Algeria, Bangladesh, Bolivia, Belarus, Burundi, Burma (Myanmar),
            Cote D&apos;Ivoire (Ivory Coast), Crimea and Sevastopol, Cuba,
            Democratic Republic of Congo, Ecuador, Iran, Iraq, Liberia, Libya,
            Magnitsky, Mali, Morocco, Nepal, North Korea, Somalia, Sudan, Syria,
            Venezuela, Yemen, Zimbabwe or any other country to which the United
            States, the United Kingdom or the European Union embargoes goods or
            imposes similar sanctions (collectively, &ldquo;
            <strong>Restricted Territories</strong>&rdquo;);
          </li>
          <li>
            you have not been identified as a Specially Designated National or
            placed on any sanctions list by the U.S. Treasury Department&rsquo;s
            Office of Foreign Assets Control, the U.S. Commerce Department, or
            the U.S. Department of State (collectively, &ldquo;
            <strong>Sanctions Lists Persons</strong>&rdquo;); and you will not
            use our Website to conduct any illegal or illicit activity;
          </li>
          <li>
            you do not intend to transact with any Restricted Person or
            Sanctions List Person;
          </li>
          <li>
            you do not, and will not, use VPN software or any other privacy or
            anonymization tools or techniques to circumvent, or attempt to
            circumvent, any restrictions that apply to the Services; and
          </li>
          <li>
            your access to the Services is not (a) prohibited by and does not
            otherwise violate or assist you to violate any domestic or foreign
            law, rule, statute, regulation, by-law, order, protocol, code,
            decree, or another directive, requirement, or guideline, published
            or in force that applies to or is otherwise intended to govern or
            regulate any person, property, transaction, activity, event or other
            matter, including any rule, order, judgment, directive or other
            requirement or guideline issued by any domestic or foreign federal,
            provincial or state, municipal, local or other governmental,
            regulatory, judicial or administrative authority having jurisdiction
            over Volmex, you, the Site or the Services, or as otherwise duly
            enacted, enforceable by law, the common law or equity (collectively,
            &ldquo;<strong>Applicable Laws</strong>&rdquo;); or (b) contribute
            to or facilitate any illegal activity.
          </li>
        </ul>
        <p>
          2.2 As a condition to accessing or using the Services or the Site, you
          acknowledge, understand, and agree to the following:
        </p>
        <ul>
          <li>
            from time to time the Site and the Services may be inaccessible or
            inoperable for any reason, including, without limitation: (a)
            equipment malfunctions; (b) periodic maintenance procedures or
            repairs that Volmex or any of its suppliers or contractors may
            undertake from time to time; (c) causes beyond Volmex&rsquo;s
            control or that Volmex could not reasonably foresee; (d) disruptions
            and temporary or permanent unavailability of underlying blockchain
            infrastructure; or (e) unavailability of third-party service
            providers or external partners for any reason;
          </li>
          <li>
            we reserve the right to disable or modify access to the Site and the
            Services at any time in the event of any breach of these Terms,
            including, without limitation, if we reasonably believe any of your
            representations and warranties may be untrue or inaccurate, and we
            will not be liable to you for any losses or damages you may suffer
            as a result of or in connection with the Site or the Services being
            inaccessible to you at any time or for any reason;
          </li>
          <li>
            the Site and the Services may evolve, which means Volmex may apply
            changes, replace, or discontinue (temporarily or permanently) the
            Services at any time in its sole discretion;
          </li>
          <li>
            the pricing information provided on the Site does not represent an
            offer, a solicitation of an offer, or any advice regarding, or
            recommendation to enter into, a transaction with Volmex;
          </li>
          <li>
            Volmex does not act as an agent for you or any other user of the
            Site or the Services;
          </li>
          <li>
            you are solely responsible for your use of the Services, including
            all of your transfers of digital assets;
          </li>
          <li>
            to the fullest not prohibited by Applicable Law, we owe no fiduciary
            duties or liabilities to you or any other party, and that to the
            extent any such duties or liabilities may exist at law or in equity,
            you hereby irrevocably disclaim, waive, and eliminate those duties
            and liabilities;
          </li>
          <li>
            you are solely responsible for reporting and paying any taxes
            applicable to your use of the Services; and
          </li>
          <li>
            we have no control over, or liability for, the delivery, quality,
            safety, legality, or any other aspect of any digital assets that you
            may transfer to or from a third party, and we are not responsible
            for ensuring that an entity with whom you transact completes the
            transaction or is authorized to do so, and if you experience a
            problem with any transactions in digital assets using the Services,
            then you bear the entire risk.
          </li>
        </ul>
        <p>
          2.3 As a condition to accessing or using the Services or the Site, you
          covenant to Volmex the following:
        </p>
        <ul>
          <li>
            in connection with using the Services, you only will transfer
            legally-obtained digital assets that belong to you;
          </li>
          <li>
            you will obey all Applicable Laws in connection with using the
            Services, and you will not use the Site or the Services if the laws
            of your country, or any other Applicable Law, prohibit you from
            doing so;
          </li>
          <li>
            any digital assets you use in connection with the Services are
            either owned by you or you are validly authorized to carry out
            actions using such digital assets; and
          </li>
          <li>
            in addition to complying with all restrictions, prohibitions, and
            other provisions of these Terms, you will (a) ensure that, at all
            times, all information that you provide on the Site and during your
            use of the Services is current, complete, and accurate; (b) maintain
            the security and confidentiality of your private keys associated
            with your public Ethereum address, passwords, private keys
            associated with your Polygon and Arbitrum account or any other
            storage scalability service account and other related credentials.
          </li>
        </ul>
        <p>
          <strong>3. Fees and Price Estimates</strong>
        </p>
        <p>
          3.1 In connection with your use of the Services, you are required to
          pay all fees necessary for interacting with the Ethereum blockchain,
          including &ldquo;gas&rdquo; fees, as well as all other fees reflected
          on the Site at the time of your use of the Services. The value of the
          gas fee changes, often unpredictably, and is entirely outside of the
          control of Volmex. Although we attempt to provide accurate fee
          information, this information reflects our estimates of fees, which
          may vary from the actual fees paid to use the Services and interact
          with the Ethereum blockchain. You acknowledge that under no
          circumstances will a transaction be invalidated, revocable,
          retractable, or otherwise unenforceable on the basis that the gas fee
          for the given transaction was unknown, too high, or otherwise
          unacceptable to a user. You also acknowledge and agree that the gas
          fee is non-refundable under all circumstances.
        </p>
        <p>
          <strong>4. No Professional Advice or Fiduciary Duties</strong>
        </p>
        <p>
          4.1 All information provided in connection with your access and use of
          the Site and the Services is for informational purposes only and
          should not be construed as professional advice. You should not take,
          or refrain from taking, any action based on any information contained
          on the Site or any other information that we make available at any
          time, including, without limitation, blog posts, articles, links to
          third-party content, discord content, news feeds, tutorials, tweets,
          and videos. Before you make any financial, legal, or other decisions
          involving the Services, you should seek independent professional
          advice from an individual who is licensed and qualified in the area
          for which such advice would be appropriate. The Terms are not intended
          to, and do not, create or impose any fiduciary duties on us. You
          further agree that the only duties and obligations that we owe you are
          those set out expressly in these Terms.
        </p>
        <p>
          <strong>5. Prohibited Activity</strong>
        </p>
        <p>
          5.1 You may not use the Services to engage in the categories of
          activity set forth below (&ldquo;<strong>Prohibited Uses</strong>
          &rdquo;). The specific activities set forth below are representative,
          but not exhaustive, of Prohibited Uses. If you are uncertain as to
          whether or not your use of the Services involves a Prohibited Use or
          have other questions about how these requirements apply to you, then
          please contact us at legal@volmex.finance. By using the Site or
          Services, you confirm that you will not use the Site or Services to do
          any of the following:
        </p>
        <ul>
          <li>
            violate any Applicable Laws including, without limitation, any
            relevant and applicable anti-money laundering and anti-terrorist
            financing laws and sanctions programs, such as, without limitation,
            the Bank Secrecy Act and the U.S. Department of Treasury&rsquo;s
            Office of Foreign Asset Controls;
          </li>
          <li>
            engage in transactions involving items that infringe or violate any
            copyright, trademark, right of publicity or privacy or any other
            proprietary right under Applicable Law, including but not limited
            to, sales, distribution, or access to counterfeit music, movies,
            software, or other licensed materials without the appropriate
            authorization from the rights holder; use of Volmex&rsquo;s
            intellectual property, name, or logo, including use of
            Volmex&rsquo;s trade or service marks, without express consent from
            Volmex or in a manner that otherwise harms Volmex; any action that
            implies an untrue endorsement by or affiliation with Volmex;
          </li>
          <li>
            use the Services in any manner that could interfere with, disrupt,
            negatively affect, or inhibit other users from fully enjoying the
            Services, or that could damage, disable, overburden, or impair the
            functioning of the Site or the Services in any manner;
          </li>
          <li>
            circumvent any content-filtering techniques, security measures or
            access controls that Volmex employs on the Site, including, without
            limitation, through the use of a VPN;
          </li>
          <li>
            use any robot, spider, crawler, scraper, or other automated means or
            interface not provided by us, to access the Services or to extract
            data, or introduce any malware, virus, Trojan horse, worm, logic
            bomb, drop-dead device, backdoor, shutdown mechanism or other
            harmful material into the Site or the Services;
          </li>
          <li>
            provide false, inaccurate, or misleading information while using the
            Site or the Services or engage in activity that operates to defraud
            Volmex, other users of the Services, or any other person;
          </li>
          <li>
            use or access the Site or Services to transmit or exchange digital
            assets that are the direct or indirect proceeds of any criminal or
            fraudulent activity, including, without limitation, terrorism or tax
            evasion;
          </li>
          <li>
            use the Site in any way that is, in our sole discretion, libelous,
            defamatory, profane, obscene, pornographic, sexually explicit,
            indecent, lewd, vulgar, suggestive, harassing, stalking, hateful,
            threatening, offensive, discriminatory, bigoted, abusive,
            inflammatory, fraudulent, deceptive, or otherwise objectionable or
            likely or intended to incite, threaten, facilitate, promote, or
            encourage hate, racial intolerance, or violent acts against others;
          </li>
          <li>
            use the Site or the Services from a jurisdiction that we have, in
            our sole discretion, determined is a jurisdiction where the use of
            the Site or the Services is prohibited;
          </li>
          <li>
            harass, abuse, or harm of another person or entity, including
            Volmex&rsquo;s employees and service providers;
          </li>
          <li>
            impersonate another user of the Services or otherwise misrepresent
            yourself; or
          </li>
          <li>
            encourage, induce or assist any third party, or yourself attempt, to
            engage in any of the activities prohibited under this Section 5 or
            any other provision of these Terms.
          </li>
        </ul>
        <p>
          <strong>6. Proprietary Rights</strong>
        </p>
        <p>
          6.1 The Site and the Servicesare governed by the Business Source
          License 1.1, a copy of which (as it applies to the Site and the
          Services) can be found at
          https://github.com/volmexfinance/volmex-core/blob/master/LICENSE and
          any other applicable licensing terms for the Site and the Services in
          these Terms (collectively, the &ldquo;<strong>Volmex License</strong>
          &rdquo;). You acknowledge that the Site or the Services may use,
          incorporate or link to certain open-source components and that your
          use of the Site or Services is subject to, and you will comply with
          any, applicable open-source licenses that govern any such open-source
          components (collectively, &ldquo;<strong>Open-Source Licenses</strong>
          &rdquo;). Without limiting the generality of the foregoing, you may
          not (a) resell, lease, lend, share, distribute, or otherwise permit
          any third party to use the Site or the Services; (b) use the Site or
          the Services for time-sharing or service bureau purposes; or (c)
          otherwise use the Site or the Services in a manner that violates the
          Volmex License or any other Open-Source Licenses.
        </p>
        <p>
          6.2 Excluding the open-source software described in Section 6.1 or
          third-party software that the Site or the Services incorporates, as
          between you and Volmex, Volmex owns the Site and the Services,
          including all technology, content, and other materials used,
          displayed, or provided on the Site or in connection with the Services
          (including all intellectual property rights subsisting therein), and
          hereby grants you a limited, revocable, transferable, license to
          access and use those portions of the Site and the Services that are
          proprietary to Volmex.
        </p>
        <p>
          6.3 Any of Volmex&rsquo;s product or service names, logos, and other
          marks used on the Site or as a part of the Services, including
          Volmex&rsquo;s name and logo are trademarks owned by Volmex, its
          affiliates, or its applicable licensors. You may not copy, imitate, or
          use them without the prior written consent of Volmex or the applicable
          licensors, and these Terms do not grant you any rights in those
          trademarks. You may not remove, obscure, or alter any legal notices
          displayed in or along with the Services.
        </p>
        <p>
          6.4 The Services are non-custodial. When you deposit digital assets
          into an account in any smart contract initially developed by Volmex,
          you retain control over those digital assets at all times. The private
          key associated with the Ethereum address from which you transfer
          digital assets and/or the private key associated with the Polygon and
          Arbitrum account or any other storage scalability service account is
          the only private key that can control the digital assets you transfer
          into these smart contracts. In some cases, you may withdraw digital
          assets from any smart contract initially developed by Volmex only to
          the Ethereum address from which you deposited the digital assets.
        </p>
        <p>
          <strong>7. Links</strong>
        </p>
        <p>
          7.1 The Services provide, or third parties may provide, links to other
          World Wide Web or accessible sites, applications, or resources. You
          acknowledge and agree that Volmex is not responsible for the
          availability of such external sites, applications or resources, and
          does not endorse and is not responsible or liable for any content,
          advertising, products, or other materials on or available from such
          sites or resources. You further acknowledge and agree that Volmex
          shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          use of or reliance on any such content, goods, or services available
          on or through any such site or resource.
        </p>
        <p>
          <strong>8. Modification, Suspension, and Termination</strong>
        </p>
        <p>
          8.1 We may, at our sole discretion, from time to time and with or
          without prior notice to you, modify, suspend or disable (temporarily
          or permanently) the Services, in whole or in part, for any reason
          whatsoever, including, without limitation, to only allow outstanding
          Volatility Tokens to be closed.&nbsp;
        </p>
        <p>
          8.2 You acknowledge and agree that Volmex will have no obligation to
          provide you with any support or maintenance in connection with the
          Website or App.
        </p>
        <p>
          8.3 Upon termination of your access, your right to use the Services
          will immediately cease. We will not be liable for any losses suffered
          by you resulting from any modification to any Services or from any
          modification, suspension, or termination, for any reason, of your
          access to all or any portion of the Site or the Services or the
          Volatility Tokens.&nbsp;
        </p>
        <p>
          8.4 Subject to this Section, these Terms will remain in full force and
          effect while you use or access the Site or Services. We may suspend or
          terminate your rights to use or access the Site or Services at any
          time for any reason at our sole discretion, including for any use of
          the Site or Services in violation of these Terms. Upon termination of
          your rights under these Terms, your right to access and use the Site
          and Services will terminate immediately. The following sections of
          these Terms will survive any termination of your access to the Site or
          the Services, regardless of the reasons for its expiration or
          termination, in addition to any other provision which by law or by its
          nature should survive: Sections 6 through 15.
        </p>
        <p>
          <strong>9. Risks</strong>
        </p>
        <p>
          9.1 By utilizing the Services or interacting with the Site in any way,
          you represent and warrant that you understand the inherent risks
          associated with cryptographic systems and blockchain-based networks;
          digital assets, including the usage and intricacies of native digital
          assets, like ether (ETH); smart contract-based tokens, such as those
          that follow the Ethereum Token Standard; and systems that interact
          with blockchain-based networks. Volmex does not own or control any of
          the underlying software through which blockchain networks are formed.
          In general, the software underlying blockchain networks, including the
          Ethereum blockchain, is open source, such that anyone can use, copy,
          modify, and distribute it.&nbsp;
        </p>
        <p>
          9.2 By using the Services, you acknowledge and agree (a) that Volmex
          is not responsible for the operation of the software and networks
          underlying the Services, (b) that there exists no guarantee of the
          functionality, security, or availability of that software and
          networks, and (c) that the underlying networks are subject to sudden
          changes in operating rules, such as those commonly referred to as
          &ldquo;forks,&rdquo; which may materially affect the Services.&nbsp;
        </p>
        <p>
          9.3 Blockchain networks use public/private key cryptography. You alone
          are responsible for securing your private key(s). We do not have
          access to your private key(s). Losing control of your private key(s)
          will permanently and irreversibly deny you access to digital assets on
          the Ethereum blockchain or other blockchain-based network. Neither
          Volmex nor any other person or entity will be able to retrieve or
          protect your digital assets. If your private key(s) are lost, then you
          will not be able to transfer your digital assets to any other
          blockchain address or wallet. If this occurs, then you will not be
          able to realize any value or utility from the digital assets that you
          may hold.
        </p>
        <p>
          9.4 The Services and your digital assets could be impacted by one or
          more regulatory inquiries or regulatory actions, which could impede or
          limit the ability of Volmex to continue to make available any
          portion(s) of the Site or Services which rely on any Volmex
          proprietary software and, thus, could impede or limit your ability to
          access or use the Services.
        </p>
        <p>
          9.5 You acknowledge and understand that cryptography is a progressing
          field with advances in code cracking or other technical advancements,
          such as the development of quantum computers, which may present risks
          to digital assets and the Services, and could result in the theft or
          loss of your digital assets. To the extent possible, we intend to
          update the smart contracts related to the Services to account for any
          advances in cryptography and to incorporate additional security
          measures necessary to address risks presented from technological
          advancements, but that intention does not guarantee or otherwise
          ensure full security of the Services.
        </p>
        <p>
          9.6 You understand that the Ethereum blockchain remains under
          development, which creates technological and security risks when using
          the Services in addition to uncertainty relating to digital assets and
          transactions therein. You acknowledge that the cost of transacting on
          the Ethereum blockchain is variable and may increase at any time
          causing impact to any activities taking place on the Ethereum
          blockchain, which may result in price fluctuations or increased costs
          when using the Services.
        </p>
        <p>
          9.7 You acknowledge that the Services are subject to flaws and that
          you are solely responsible for evaluating any code provided by the
          Services or Site. This warning and others Volmex provides in these
          Terms in no way evidence or represent an on-going duty to alert you to
          all of the potential risks of utilizing the Services or accessing the
          Site.
        </p>
        <p>
          9.8 Although we intend to provide accurate and timely information on
          the Site and during your use of the Services, the Site and other
          information available when using the Services may not always be
          entirely accurate, complete, or current and may also include technical
          inaccuracies or typographical errors. To continue to provide you with
          as complete and accurate information as possible, information may be
          changed or updated from time to time without notice, including,
          without limitation, information regarding our policies. Accordingly,
          you should verify all information before relying on it, and all
          decisions based on information contained on the Site or as part of the
          Services are your sole responsibility. No representation is made as to
          the accuracy, completeness, or appropriateness for any particular
          purpose of any pricing information distributed via the Site or
          otherwise when using the Services. Prices and pricing information may
          be higher or lower than prices available on platforms providing
          similar services.
        </p>
        <p>
          9.9 Any use or interaction with the Services requires a comprehensive
          understanding of applied cryptography and computer science to
          appreciate the inherent risks, including those listed above. You
          represent and warrant that you possess relevant knowledge and skills.
          Any reference to a type of digital asset on the Site or otherwise
          during the use of the Services does not indicate our approval or
          disapproval of the technology on which the digital asset relies, and
          should not be used as a substitute for your understanding of the risks
          specific to each type of digital asset.
        </p>
        <p>
          9.10 Use of the Services, in particular for trading digital assets,
          and issuing or acquiring Volatility Tokens or Volmex Perpetual
          Contracts, may carry financial risk. Digital assets, especially in
          connection with Volatility Tokens and Volmex Perpetual Contracts, are,
          by their nature, highly experimental, risky, and volatile.
          Transactions entered into in connection with the Services are
          irreversible, final and there are no refunds. You acknowledge and
          agree that you will access and use the Site and the Services at your
          own risk. The risk of loss in trading digital assets, especially
          issuing or acquiring Volatility Tokens or Volmex Perpetual Contracts,
          can be substantial. You should, therefore, carefully consider whether
          such trading is suitable for you in light of your circumstances and
          financial resources. By using the Services, you represent and warrant
          that you have been, are, and will be solely responsible for making
          your independent appraisal and investigations into the risks of a
          given transaction and the underlying digital assets, including
          Volatility Tokens and Volmex Perpetual Contracts. You represent that
          you have sufficient knowledge, market sophistication, professional
          advice, and experience to make your evaluation of the merits and risks
          of any transaction conducted in connection with the Services or any
          digital asset. You accept all consequences of using the Services,
          including the risk that you may lose access to your digital assets
          indefinitely. All transaction decisions are made solely by you.
          Notwithstanding anything in these Terms, we accept no responsibility
          whatsoever for, and will in no circumstances be liable to you in
          connection with, your use of the Services for performing digital asset
          transactions, including issuing or acquiring Volatility Tokens or
          Volmex Perpetual Contracts.
        </p>
        <p>
          9.11 We must comply with Applicable Law, which may require us to, upon
          request by government agencies, take certain actions or provide
          information, which may not be in your best interests.
        </p>
        <p>
          9.12 You understand that the storage scalability services provided by
          Polygon, Arbitrum, or other storage scalability service, and our
          Services remain under development, which creates technological,
          trading, and other risks when using the Services. These risks include,
          among others, delays in trades, withdrawals, and deposits resulting
          from the servers of Volmex, Polygon, Arbitrum,&nbsp; or other storage
          scalability services being offline; an incorrect display of
          information on the Site in the case of server errors; or transactions
          using the Services being rolled back in the case of server errors. You
          acknowledge that these risks may have a material impact on your
          transactions using the Services, which may result in, among other
          things, failing to fulfill transactions at your desired price or at
          all.
        </p>
        <p>
          9.13 You hereby acknowledge and agree that Volmex will have no
          responsibility or liability for, the risks set forth in this Section
          9. You hereby irrevocably waive, release and discharge all claims,
          whether known or unknown to you, against Volmex, its affiliates, and
          their respective shareholders, members, directors, officers,
          employees, agents, and representatives, suppliers, and contractors
          related to any of the risks set forth in this Section 9.
        </p>
        <p>
          <strong>10. Indemnification</strong>
        </p>
        <p>
          10.1 You will defend, indemnify, and hold harmless Volmex, its
          affiliates, and its and its affiliates&rsquo; respective stockholders,
          members, directors, officers, managers, employees, attorneys, agents,
          representatives, suppliers, and contractors (collectively, &ldquo;
          <strong>Indemnified Parties</strong>&rdquo;) from any claim, demand,
          lawsuit, action, proceeding, investigation, liability, damage, loss,
          cost or expense, including without limitation reasonable
          attorneys&rsquo; fees, arising out of or relating to (a) your use of,
          or conduct in connection with, the Site or the Services (including,
          without limitation, the Polygon and Arbitrum services); (b) digital
          assets associated with your Ethereum address; (c) any feedback or user
          content you provide to Volmex, if any, concerning the Site or the
          Services; (d) your violation of these Terms; or (e) your infringement
          or misappropriation of the rights of any other person or entity. If
          you are obligated to indemnify any Indemnified Party, Volmex (or, at
          its discretion, the applicable Indemnified Party) will have the right,
          in its sole discretion, to control any action or proceeding and to
          determine whether Volmex wishes to settle, and if so, on what terms,
          and you agree to corporate with Volmex in the defense.
        </p>
        <p>
          <strong>11. Disclosures; Disclaimers</strong>
        </p>
        <p>
          11.1 Volmex is a developer of open-source software. Volmex does not
          operate a digital asset or derivatives exchange platform or offer
          trade execution or clearing services and, therefore, has no oversight,
          involvement, or control concerning your transactions using the
          Services. All transactions between users of open-source software
          initially developed by Volmex are executed peer-to-peer directly
          between the users&rsquo; Ethereum addresses through a smart contract.
          You are responsible for complying with all Applicable Laws that govern
          your Volatility Tokens and Volmex Perpetual Contracts, including, but
          not limited to, the Commodity Exchange Act and the regulations
          promulgated thereunder by the U.S. Commodity Futures Trading
          Commission (&ldquo;<strong>CFTC</strong>&rdquo;), the federal
          securities laws and the regulations promulgated thereunder by the U.S.
          Securities and Exchange Commission (&ldquo;<strong>SEC</strong>
          &rdquo;) and all foreign Applicable Laws. No U.S. Person may issue or
          acquire Volatility Tokens or Volmex Perpetual Contracts using the
          Services.
        </p>
        <p>
          11.2 You understand that Volmex is not registered or licensed by the
          CFTC, SEC, or any financial regulatory authority. No financial
          regulatory authority has reviewed or approved the use of the
          open-source software. The Site and the open-source software initially
          developed by Volmex do not constitute advice or a recommendation
          concerning any commodity, security, or other digital asset or
          instrument. Volmex is not acting as an investment adviser or commodity
          trading adviser to any person or entity.
        </p>
        <p>
          11.3 You understand that Volatility Tokens and Volmex Perpetual
          Contracts entered into using the open-source software initially
          developed by Volmex are blockchain-based synthetic asset contracts
          which aim to track an off chain reference index price. Volatility
          Tokens and Volmex Perpetual Contracts are not shares or any equivalent
          in any existing or future public or private company, corporation, or
          other entity in any jurisdiction.
        </p>
        <p>
          11.4 Volmex does not own or control the underlying software protocols
          that are used in connection with Volatility Tokens or Volmex Perpetual
          Contracts. In general, the underlying protocols are opensource and
          anyone can use, copy, modify, and distribute them. Volmex is not
          responsible for the operation of the underlying protocols, and Volmex
          makes no guarantee of their functionality, security, or availability.
        </p>
        <p>
          11.5 Volmex shall not be responsible in any way for any transactions
          you enter into with other users. You agree that Volmex will not be
          liable for any loss or damages of any sort incurred as the result of
          any interactions between you and other users.
        </p>
        <p>
          11.6 To the maximum extent permitted under Applicable Law, the Site
          and the Services (and any of their content or functionality) provided
          by or on behalf of us are provided on an &ldquo;AS IS&rdquo; and
          &ldquo;AS AVAILABLE&rdquo; basis, and we expressly disclaim, and you
          hereby waive, any representations, conditions or warranties of any
          kind, whether express or implied, legal, statutory or otherwise, or
          arising from statute, otherwise in law, course of dealing, or usage of
          trade, including, without limitation, the implied or legal warranties
          and conditions of merchantability, merchantable quality, quality or
          fitness for a particular purpose, title, security, availability,
          reliability, accuracy, quiet enjoyment and non-infringement of third
          party rights. Without limiting the foregoing, we do not represent or
          warrant that the Site or the Services (including any data relating
          thereto) will be uninterrupted, available at any particular time, or
          error-free. Further, we do not warrant that errors in the Site or the
          Service are correctable or will be correctable.
        </p>
        <p>
          11.7 You acknowledge that your data on the Site may become
          irretrievably lost or corrupted or temporarily unavailable due to a
          variety of causes, and agree that, to the maximum extent permitted
          under Applicable Law, we will not be liable for any loss or damage
          caused by denial-of-service attacks, software failures, viruses or
          other technologically harmful materials (including those which may
          infect your computer equipment), protocol changes by third-party
          providers, internet outages, force majeure events or other disasters,
          scheduled or unscheduled maintenance, or other causes either within or
          outside our control.
        </p>
        <p>
          11.8 The disclaimer of implied warranties contained herein may not
          apply if and to the extent such warranties cannot be excluded or
          limited under the Applicable Law of the jurisdiction in which you
          reside.
        </p>
        <p>
          11.9 ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND
          RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE
          OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.&nbsp;&nbsp;
        </p>
        <p>
          <strong>12. Limitation of Liability</strong>
        </p>
        <p>
          12.1 In no event shall Volmex, its affiliates, its suppliers and
          contractors, and its and its affiliates&rsquo;, suppliers&rsquo; and
          contractors&rsquo; respective stockholders, members, directors,
          officers, managers, employees, attorneys, agents, representatives,
          suppliers, and contractors be liable for any incidental, indirect,
          special, punitive, consequential or similar damages or liabilities
          whatsoever (including, without limitation, damages for loss of fiat,
          assets, data, information, revenue, opportunities, use, goodwill,
          profits or other business or financial benefit) arising out of or in
          connection with the Site and the Services (and any of their content
          and functionality, including, without limitation, the storage
          scalability services including the Polygon and Arbitrum services), any
          execution or settlement of a transaction, any performance or
          non-performance of the Services, your digital assets, Volatility
          Tokens or Volmex Perpetual Contracts, or any other product, service or
          other item provided by or on behalf of Volmex, whether under contract,
          tort (including negligence), civil liability, statute, strict
          liability, breach of warranties, or under any other theory of
          liability, and whether or not we have been advised of, knew of or
          should have known of the possibility of such damages and
          notwithstanding any failure of the essential purpose of these Terms or
          any limited remedy hereunder nor is Volmex in any way responsible for
          the execution or settlement of transactions between users of
          open-source software initially developed by Volmex.
        </p>
        <p>
          12.2 In no event shall Volmex&rsquo;s aggregate liability (together
          with its affiliates, including its and its affiliates&rsquo;
          respective stockholders, members, directors, managers, officers,
          employees, attorneys, agents, representatives, suppliers, or
          contractors) arising out of or in connection with the Site and the
          Services (and any of their content and functionality), any performance
          or nonperformance of the Services, your digital assets, Volatility
          Tokens or Volmex Perpetual Contracts, or any other product, service or
          other item provided by or on behalf of Volmex, whether under contract,
          tort (including negligence), civil liability, statute, strict
          liability or other theory of liability exceed the amount of fees paid
          by you to Volmex under these Terms, if any, in the twelve (12) month
          period immediately preceding the event giving rise to the claim for
          liability.
        </p>
        <p>
          <strong>13. Dispute Resolution &amp; Arbitration</strong>
        </p>
        <p>
          13.1 Please read the following section carefully because it requires
          you to arbitrate certain disputes and claims with Volmex and limits
          how you can seek relief from Volmex. Also, arbitration precludes you
          from suing in court or having a jury trial.
        </p>
        <p>
          13.2 You and Volmex agree that any dispute arising out of or related
          to these Terms or the Services is personal to you and Volmex and that
          any dispute will be resolved solely through individual action, and
          will not be brought as a class arbitration, class action, or any other
          type of representative proceeding.
        </p>
        <p>
          13.3 Except for small claims disputes in which you or Volmex seeks to
          bring an individual action in small claims court located in the county
          of your billing address or disputes in which you or Volmex seeks
          injunctive or other equitable relief for the alleged unlawful use of
          intellectual property, you and Volmex waive your rights to a jury
          trial and to have any dispute arising out of or related to these Terms
          or the Services resolved in court. Instead, for any dispute or claim
          that you have against Volmex or relating in any way to the Services,
          you agree to first contact Volmex and attempt to resolve the claim
          informally by sending a written notice of your claim (&ldquo;
          <strong>Notice</strong>&rdquo;) to Volmex by email at
          legal@volmex.finance. The Notice must (a) include your name, residence
          address, email address, and telephone number; (b) describe the nature
          and basis of the claim; and (c) set forth the specific relief sought.
          Our notice to you will be similar in form to that described above. If
          you and Volmex cannot reach an agreement to resolve the claim within
          thirty (30) days after such Notice is received, then either party may
          submit the dispute to binding arbitration administered by JAMS or,
          under the limited circumstances set forth above, in court. All
          disputes submitted to JAMS will be resolved through confidential,
          binding arbitration before one arbitrator. Arbitration proceedings
          will be held in San Francisco, California, under the JAMS Streamlined
          Arbitration Rules and Procedures (&ldquo;<strong>JAMS Rules</strong>
          &rdquo;). The most recent version of the JAMS Rules are available on
          the JAMS website and are hereby incorporated by reference. You either
          acknowledge and agree that you have read and understand the JAMS Rules
          or waive your opportunity to read the JAMS Rules and waive any claim
          that the JAMS Rules are unfair or should not apply for any reason.
        </p>
        <p>
          13.4 You and Volmex agree that these Terms affect interstate commerce
          and that the enforceability of this Section 13 will be substantively
          and procedurally governed by the Federal Arbitration Act, 9 U.S.C.
          &sect; 1, et seq. (the &ldquo;<strong>FAA</strong>&rdquo;), to the
          maximum extent permitted by applicable law. As limited by the FAA,
          these Terms and the JAMS Rules, the arbitrator will have exclusive
          authority to make all procedural and substantive decisions regarding
          any dispute and to grant any remedy that would otherwise be available
          in court, including the power to determine the question of
          arbitrability. The arbitrator may conduct only an individual
          arbitration and may not consolidate more than one individual&rsquo;s
          claims, preside over any type of class or representative proceeding or
          preside over any proceeding involving more than one individual.
        </p>
        <p>
          13.5 The arbitrator, Volmex, and you will maintain the confidentiality
          of any arbitration proceedings, judgments and awards, including, but
          not limited to, all information gathered, prepared, and presented for
          purposes of the arbitration or related to the dispute(s) therein. The
          arbitrator will have the authority to make appropriate rulings to
          safeguard confidentiality unless the law provides to the contrary. The
          duty of confidentiality does not apply to the extent that disclosure
          is necessary to prepare for or conduct the arbitration hearing on the
          merits, in connection with a court application for a preliminary
          remedy or in connection with a judicial challenge to an arbitration
          award or its enforcement, or to the extent that disclosure is
          otherwise required by law or judicial decision.
        </p>
        <p>
          13.6 You and Volmex agree that for any arbitration you initiate, you
          will pay the filing fee and all other JAMS fees and costs. For any
          arbitration initiated by Volmex, Volmex will pay all JAMS fees and
          costs. You and Volmex agree that the state or federal courts of the
          State of Delaware and the United States sitting in Dover, DE have
          exclusive jurisdiction over the enforcement of an arbitration award.
        </p>
        <p>
          13.7 Any claim arising out of or related to these Terms or the
          Services must be filed within one year after such claim arose;
          otherwise, the claim is permanently barred, which means that you and
          Volmex will not have the right to assert the claim.
        </p>
        <p>
          13.8 If any portion of this Section 13 is found to be unenforceable or
          unlawful for any reason, (a) the unenforceable or unlawful provision
          shall be severed from these Terms; (b) severance of the unenforceable
          or unlawful provision shall have no impact whatsoever on the remainder
          of this Section 13 or the parties&rsquo; ability to compel arbitration
          of any remaining claims on an individual basis under this Section 13;
          and (c) to the extent that any claims must therefore proceed on a
          class, collective, consolidated, or representative basis, such claims
          must be litigated in a civil court of competent jurisdiction and not
          in arbitration, and the parties agree that litigation of those claims
          shall be stayed pending the outcome of any individual claims in
          arbitration. Further, if any part of this Section 13 is found to
          prohibit an individual claim seeking public injunctive relief, then
          that provision will have no effect to the extent such relief is
          allowed to be sought out of arbitration, and the remainder of this
          Section 13 will be enforceable.
        </p>
        <p>
          <strong>14. Governing Law</strong>
        </p>
        <p>
          14.1 The interpretation and enforcement of these Terms, and any
          dispute related to these Terms, the Site or the Services, will be
          governed by and construed and enforced under the laws of the State of
          California, as applicable, without regard to conflict of law rules or
          principles (whether of the State of California or any other
          jurisdiction) that would cause the application of the laws of any
          other jurisdiction. You agree that we may initiate a proceeding
          related to the enforcement or validity of our intellectual property
          rights in any court having jurisdiction. For any other proceeding that
          is not subject to arbitration under these Terms, the state and federal
          courts located in San Francisco, California will have exclusive
          jurisdiction. You waive any objection to venue in any such courts.
        </p>
        <p>
          <strong>15. General Information</strong>
        </p>
        <p>
          15.1 Please refer to our privacy policy, which is incorporated herein
          by reference and available here at https://volmex.finance/privacy, for
          information about how we collect, use, share and otherwise process
          information about you.
        </p>
        <p>
          15.2 You consent to receive all communications, agreements, documents,
          receipts, notices, and disclosures electronically (collectively, our
          &ldquo;<strong>Communications</strong>&rdquo;) that we provide in
          connection with these Terms or any Services. You agree that we may
          provide our Communications to you by posting them on the Site or by
          emailing them to you at the email address you provide in connection
          with using the Services, if any. You should maintain copies of our
          Communications by printing a paper copy or saving an electronic copy.
          You may also contact us with questions, complaints, or claims
          concerning the Services at legal@volmex.finance.
        </p>
        <p>
          15.3 Any right or remedy of Volmex set forth in these Terms is in
          addition to, and not in lieu of, any other right or remedy whether
          described in these Terms, under Applicable Law, at law, or in equity.
          The failure or delay of Volmex in exercising any right, power, or
          privilege under these Terms shall not operate as a waiver thereof.
        </p>
        <p>
          15.4 The invalidity or unenforceability of any of these Terms shall
          not affect the validity or enforceability of any other of these Terms,
          all of which shall remain in full force and effect.
        </p>
        <p>
          15.5 We will have no responsibility or liability for any failure or
          delay in performance of the Site or any of the Services, or any loss
          or damage that you may incur, due to any circumstance or event beyond
          our control, including without limitation any flood, extraordinary
          weather conditions, earthquake, or other act of God, fire, war,
          insurrection, riot, labor dispute, accident, action of government,
          communications, power failure, or equipment or software malfunction.
        </p>
        <p>
          15.6 You may not assign or transfer any right to use the Site or the
          Services, or any of your rights or obligations under these Terms,
          without our express prior written consent, including by operation of
          law or in connection with any change of control. We may assign or
          transfer any or all of our rights or obligations under these Terms, in
          whole or in part, without notice or obtaining your consent or
          approval.
        </p>
        <p>
          15.7 Headings of sections are for convenience only and shall not be
          used to limit or construe such sections.
        </p>
        <p>
          15.8 These Terms contain the entire agreement between you and Volmex,
          and supersede all prior and contemporaneous understandings between the
          parties regarding the Site and the Services.
        </p>
        <p>
          15.9 In the event of any conflict between these Terms and any other
          agreement you may have with us, these Terms will control unless the
          other agreement specifically identifies these Terms and declares that
          the other agreement supersedes these Terms.
        </p>
        <p>
          15.10 You agree that, except as otherwise expressly provided in this
          Agreement, there shall be no third-party beneficiaries to the
          Agreement other than the Indemnified Parties.
        </p>
        <p>
          <strong>SUPPLEMENTAL TERMS AND CONDITIONS</strong>
        </p>
        <p>
          <strong>Last Updated June 9, 2023</strong>
        </p>
        <p>
          Volmex Labs Corporation (“<span className="underline">Company</span>,”
          “<span className="underline">we</span>,” “
          <span className="underline">us</span>,” or “
          <span className="underline">our</span>”) welcomes you.
        </p>
        <p>
          We provide our testnet faucet solely for our own software development
          testing purposes (and not for monetization of the assets therein by
          any party, including, without limitation, any party receiving rewards
          from the Company for participation in such activities) (“
          <span className="underline">Testing Services</span>” and, together
          with the Services (as defined in the Terms (as defined below)) and
          external sites, applications and/or resources described in Section 7
          of the Terms, the “<span className="underline">Services</span>”)
          subject to these Supplemental Terms and Conditions, which may be
          updated by us from time to time without notice to you. By using the
          Testing Services, you acknowledge that you have read, understood, and
          agree to be legally bound by the terms and conditions of these
          Supplemental Terms and Conditions and the terms and conditions of our
          Privacy Policy and the terms and conditions of the Terms of Use (the
          “Terms”), which are all hereby incorporated by reference
          (collectively, this “<span className="underline">Agreement</span>”).
          If you do not agree to any of these terms, then please do not use the
          Services.
        </p>
        <p>
          Capitalized terms not defined in these Supplemental Terms and
          Conditions shall have the meaning set forth in the Terms of Use.
        </p>
        <p>
          <strong>1. TESTING SERVICES</strong>
        </p>
        <p>
          You may utilize the Testing Services by connecting your wallet or
          following our other instructions related to such offering. All Testing
          Services will be subject to this Agreement and any additional Testing
          Service terms listed on the Service for delivery thereof.
        </p>
        <p>
          You acknowledge, agree and covenant that (i) you understand that the
          Testing Services are intended to be offered as non-securities not
          subject to the US federal or state securities laws, (ii) you will not
          use the Testing Services other than for purposes of the Testing
          Services, (iii) you will not attempt to monetize the assets made
          available in connection with the Testing Services and (iv) you will
          not take any action that could result in any asset made available in
          connection with the Testing Services constituting a security under
          applicable US federal and state securities laws. The terms of this
          paragraph are collectively referred to as the “Key Terms”.
        </p>
        <p>
          You release (and agree and covenant not to sue) the Company for any
          breach of the Key Terms by you.
        </p>
        <p>
          <span className="underline italic">
            Links, Dispute Resolution & Arbitration and Governing Law
          </span>
          : Sections 7, 13 and 14 of the Terms of Use is incorporated herein,
          mutatis mutandis.
        </p>
        <p>
          <strong>2. EQUITABLE RELIEF</strong>
        </p>
        <p>
          You acknowledge and agree that in the event of a breach or threatened
          violation of these Supplemental Terms and Conditions by you, we will
          suffer irreparable harm and will therefore be entitled to injunctive
          relief to enforce this Agreement. We may, without waiving any other
          remedies under this Agreement, seek from any court having jurisdiction
          any interim, equitable, provisional, or injunctive relief that is
          necessary to protect our rights and property (as well as the property
          of others) pending the outcome of any related arbitration.
        </p>
        <p>
          <strong>3. MISCELLANEOUS</strong>
        </p>
        <p>
          The provisions of this Agreement shall be deemed severable and the
          invalidity or unenforceability of any provision shall not affect the
          validity or enforceability of the other provisions hereof. If any
          provision of this Agreement, or the application thereof to any person,
          entity or circumstance, is found to be invalid or unenforceable in any
          jurisdiction, (a) a suitable and equitable provision shall be
          substituted therefor in order to carry out, so far as may be valid or
          enforceable, such provision and (b) the remainder of this Agreement
          and the application of such provision to other persons, entities or
          circumstances shall not be affected by such invalidity or
          unenforceability, nor shall such invalidity or unenforceability affect
          the validity or enforceability of such provision, or the application
          thereof, in any other jurisdiction. The recitals to these Supplemental
          Terms and Conditions are incorporated herein,{` `}
          <span className="italic">mutatis mutandis.</span>
        </p>
      </div>
    </Container>
  </Layout>
);

export default TermsOfService;
